import React from 'react'
import { Error, PageWrap, SEO } from '../components'

const ErrorPage = () => (
	<>
		<SEO title="Error" />
		<PageWrap omitFooter={true}>
			<Error />
		</PageWrap>
	</>
)

export default ErrorPage
